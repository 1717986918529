import store from "@/store"; // 直接取store
import { tools } from "@/assets/tools.js";
import { deepParseJson } from "deep-parse-json";
export const moneyout = {
  時間起迄查詢會員出金紀錄: ({
    時間起 ,
    時間迄,
    SearchCol = "fMid",
    SearchVal = "",
  } = {}) => {
    store
      .dispatch("apiMoneyOut/A04_查詢_出金紀錄", {
        時間起,
        時間迄,
        SearchCol,
        SearchVal,
        pageIndex:1,
        pageSize:1500,
      })
      .then((res) => {
        let data = deepParseJson(res.data);
        console.log("取得", data);
        store.commit("apiMoneyOut/SET_入金紀錄", data); //deepparseJson
      });
  },
  時間起迄查詢出金紀錄: ({
      時間起 , 
      時間迄, 
      SearchCol = "f狀態",
      SearchVal = "",
      FuzzyCol = "f銀行紀錄",
      FuzzyVal = "",
    } = {}) => {
      console.log('這這這這',SearchVal);
      if( SearchVal=='null'){
        if(FuzzyVal=='null'){
          store
          .dispatch("apiMoneyOut/A04_查詢_出金紀錄", {
            時間起,
            時間迄,
            pageIndex:1,
            pageSize:1500,
          })
          .then((res) => {
            let data = deepParseJson(res.data);
            console.log("取得", data);
            store.commit("apiMoneyOut/SET_入金紀錄", data); //deepparseJson
          });
        }else{
          store
          .dispatch("apiMoneyOut/A04_查詢_出金紀錄", {
            時間起,
            時間迄,
            FuzzyCol ,
            FuzzyVal ,
            pageIndex:1,
            pageSize:1500
          })
          .then((res) => {
            let data = deepParseJson(res.data);
            console.log("取得", data);
            store.commit("apiMoneyOut/SET_入金紀錄", data); //deepparseJson
          });
        }
    
      }else{
        store
        .dispatch("apiMoneyOut/A04_查詢_出金紀錄", {
          時間起,
          時間迄,
          SearchCol,
          SearchVal,
          pageIndex:1,
          pageSize:1500,
        })
        .then((res) => {
          let data = deepParseJson(res.data);
          console.log("取得", data);
          store.commit("apiMoneyOut/SET_入金紀錄", data); //deepparseJson
        });
      }
    
  },
  上傳退款TXID: (出金編號, 退款TXID, 審核失敗原因) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          "apiMoneyOut/A03_上傳退款TXID_修改",
          tools.ObjToForm({ 出金編號, 退款TXID, 審核失敗原因 })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  上傳匯款單: (出金編號, 匯款單) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          "apiMoneyOut/A02_上傳匯款單_修改",
          tools.ObjToForm({ 出金編號, 匯款單 })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
