<template>
  <div>
    <DatePicker :key="重新渲染" :篩選種類="篩選種類"  @valueUpdate="selectTime" class="px-2 border border-gray-100" Color="red">
      <template #buttonName>{{目前選擇種類}} ▼</template>
      <template #dropdownList
        ><ul
          class="py-1 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefaultButton"
        >
          <li @click="篩選種類點選('全部')"  class="hover:bg-[#389568]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >全部</a
            >
          </li>
          <li  @click="篩選種類點選('申請中')" class="hover:bg-[#389568]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >申請中</a
            >
          </li>
          <li  @click="篩選種類點選('審核中')" class="hover:bg-[#389568]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >審核中</a
            >
          </li>
          <li  @click="篩選種類點選('審核失敗')" class="hover:bg-[#389568]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >審核失敗</a
            >
          </li>
          <li @click="篩選種類點選('完成交易')" class="hover:bg-[#389568]">
            <a
              href="#"
              class="block px-4 py-2 hover:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              >完成交易</a
            >
          </li>
        </ul></template
      >
    </DatePicker>
    <img
      v-if="$store.state.apiMoneyOut.入金紀錄.length == 0"
      src="@/assets/img/icon-nomoneyIn.svg"
      alt="male icon"
      class="mx-auto mt-5"
    />
    <div
      v-else
      class="flex w-full mb-3"
      v-for="(item, key) in $store.state.apiMoneyOut.入金紀錄"
      :key="key"
    >
      <div
        class="px-4 py-3 w-[80%] border border-gray-300"
        style="border-radius: 0.7rem"
      >
        <div
          class="pl-2 flex justify-between items-center text-[#989898] text-sm"
        >
          <span>{{ item.f新增時間.split(".")[0].replace("T", " ") }}</span>
          <div class="flex">
            <img
              src="@/assets/img/icon-detail.svg"
              alt="male icon"
              @click="pop('紀錄詳細', item)"
            />
            <div
              class="text-white ml-2 py-1 px-3"
              :class="statusColor[`@${item.f狀態}`]"
              style="border-radius: 0.2rem"
            >
              {{ statusText[`@${item.f狀態}`] }}
            </div>
          </div>
        </div>
        <div class="pl-2 flex flex-col mt-3 text-[#989898]">
          <span>當日匯率 1USDT：{{ item.f結算匯率 }} TWD</span
          ><span class="text-2xl text-[#389568]"
            ><span class="text-4xl">{{ item.f兌換金額 }} USDT</span> /
            {{ item.f結算金額 }} TWD</span
          >
        </div>
        <div
          class="mt-3 p-2 flex flex-col bg-[#f4f3f3] text-[#717171]"
          style="border-radius: 0.7rem"
        >
          <span
            >({{ item.f銀行紀錄.銀行代號 }}){{ item.f銀行紀錄.銀行名稱 }}
            {{ item.f銀行紀錄.分行名稱 }} {{ item.f銀行紀錄.銀行帳戶 }}</span
          >
        </div>
      </div>
      <div class="flex flex-col w-[20%]">
        <div class="flex justify-around">
          <div
            class="cursor-pointer flex flex-col justify-center items-center w-[90%] py-2"
            :class="!上傳TXID(item).total ? 'grayscale' : ''"
            style="border: 2px solid #389568; border-radius: 0.5rem"
            @click="
              上傳TXID(item).look ? 顯示TXID(item) : pop('上傳TXID', item)
            "
          >
            <img
              class="w-[20%]"
              src="@/assets/img/icon-watchtxid.svg"
              alt="male icon"
            />
            <span
              v-if="!上傳TXID(item).look"
              class="pt-2 text-xl font-medium text-center text-[#389568]"
              >上傳TXID</span
            >
            <span
              v-else
              class="pt-2 text-xl font-medium text-center text-[#389568]"
              >查看TXID</span
            >
          </div>
        </div>
        <div class="cursor-pointer flex justify-around mt-3">
          <div
            class="cursor-pointer flex flex-col justify-center items-center w-[90%] py-2"
            :class="!上傳匯款單(item).total ? 'grayscale' : ''"
            style="border: 2px solid #389568; border-radius: 0.5rem"
            @click="
             !上傳匯款單(item).total?null: 上傳匯款單(item).look ? 顯示匯款單(item) : pop('上傳匯款單', item)
            "
          >
            <img
              class="w-[20%]"
              src="@/assets/img/icon-watchtxid.svg"
              alt="male icon"
            />
      
            <span
              v-if="!上傳匯款單(item).look"
              class="pt-2 text-xl font-medium text-center text-[#389568]"
              >上傳匯款單</span
            >
            <span
              v-else
              class="pt-2 text-xl font-medium text-center text-[#389568]"
              >查看匯款單</span
            >
          </div>
        </div>
        <div class="cursor-pointer flex justify-around mt-3">
          <div
            class="cursor-pointer flex flex-col justify-center items-center w-[90%] py-2"
            :class="!上傳退款TXID(item).total ? 'grayscale' : ''"
            style="border: 2px solid #389568; border-radius: 0.5rem"
            @click="
             !上傳退款TXID(item).total?null: 上傳退款TXID(item).look
                ? 顯示退款TXID(item)
                : pop('上傳退款TXID', item)
            "
          >
            <img
              class="w-[20%]"
              src="@/assets/img/icon-watchtxid.svg"
              alt="male icon"
            />
            <span
              v-if="!上傳退款TXID(item).look"
              class="pt-2 text-xl font-medium text-center text-[#389568]"
              >上傳退款TXID</span
            >
            <span
              v-else
              class="pt-2 text-xl font-medium text-center text-[#389568]"
              >查看退款TXID</span
            >
          </div>
        </div>
      </div>
    </div>
    <popcorn
      name="紀錄詳細"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">查看紀錄</span>
          <div
            class="mt-3 px-4 py-3 w-[100%] border border-gray-300"
            style="border-radius: 0.7rem"
          >
            <div
              class="pl-2 flex justify-between items-center text-[#989898] text-xs"
            >
              <span>{{
                select.f新增時間.split(".")[0].replace("T", " ")
              }}</span>
              <div class="flex">
                <div
                  class="text-white ml-2 py-1 px-3"
                  :class="statusColor[`@${select.f狀態}`]"
                  style="border-radius: 0.2rem"
                >
                  {{ statusText[`@${select.f狀態}`] }}
                </div>
              </div>
            </div>
            <div class="pl-2 flex flex-col mt-3 text-[#989898] text-sm">
              <span>當日匯率 1USDT：{{ select.f結算匯率 }} TWD</span
              ><span class="text-2xl text-[#389568]"
                ><span class="text-4xl">{{ select.f兌換金額 }} USDT</span> /
                {{ select.f結算金額 }} TWD</span
              >
            </div>
            <div
              class="mt-3 p-2 flex flex-col bg-[#f4f3f3] text-[#717171] text-sm"
              style="border-radius: 0.7rem"
            >
              <span
                >({{ select.f銀行紀錄.銀行代號 }}){{ select.f銀行紀錄.銀行名稱
                }}<br />
                {{ select.f銀行紀錄.分行名稱 }}<br />
                {{ select.f銀行紀錄.銀行帳戶 }}</span
              >
            </div>
          </div>
          <span
            class="text-[#959595] text-sm text-bolder flex items-center mt-5"
            ><span class="text-xl mr-1">修改紀錄</span>Amendment record</span
          >
          <span v-if="取得修改紀錄.length == 0" class="mt-5 text-lg"
            >暫無修改紀錄</span
          >
          <Table
          style="font-size: 14px;"
            v-else
            class="mt-5"
            :rows="取得修改紀錄"
            :cols="查看修改紀錄"
            :disableStatus="false"
          >
          </Table>
        </template>
        <template #footer>
          <!-- <Button
            label="完成上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳匯款單_EVENT()"
          /> -->
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="上傳匯款單"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">上傳匯款單</span>
          <ImgUpload
            :readonly="false"
            v-model="匯款單"
            @changeEvent="
              (value) => {
                匯款單 = value;
              }
            "
          />
        </template>
        <template #footer>
          <Button
            label="完成上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳匯款單_EVENT()"
          />
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="上傳退款TXID"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">上傳退款TXID</span>
          <Input
            class="mt-5"
            placeholder="請填寫此筆訂單的TXID"
            filled
            hint="gray"
            text="gray"
            v-model="退款TXID.id"
          >
            <template #end>
              <Icon icon="icon-search" />
            </template>
          </Input>
          <Input
            class="mt-5"
            placeholder="請填寫此筆訂單退款原因"
            filled
            hint="gray"
            text="gray"
            v-model="退款TXID.審核失敗原因"
          >
            <template #end>
              <Icon icon="icon-search" />
            </template>
          </Input>
          <img src="@/assets/img/icon-txid.svg" alt="male icon" />
        </template>
        <template #footer>
          <Button
            label="完成上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳退款TXID_EVENT()"
          />
        </template>
      </Card>
    </popcorn>

    <popcorn
      name="查看TXID"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">查看TXID</span>
          <Input
            class="mt-5"
            filled
            hint="gray"
            text="gray"
            :readonly="true"
            v-model="select.fTXID"
          >
            <template #end>
              <Icon icon="icon-search" />
            </template>
          </Input>
        </template>
        <template #footer>
          <!-- <Button
            label="上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳TXID_EVENT()"
          /> -->
        </template>
      </Card>
    </popcorn>
    <popcorn
      name="查看退款TXID"
      :width="350"
      :height="'auto'"
      :scrollable="true"
      :clickToClose="true"
    >
      <Card color="blue" type="dialog" :iconhead="true">
        <template #body>
          <span class="text-lg text-bolder">查看退款TXID</span>
          <Input
            class="mt-5"
            filled
            hint="gray"
            text="gray"
            :readonly="true"
            v-model="select.f退款TXID"
          >
            <template #end>
              <Icon icon="icon-search" />
            </template>
          </Input>
        </template>
        <template #footer>
          <!-- <Button
            label="上傳"
            color="green"
            text="white"
            size="popup-md"
            @click="上傳TXID_EVENT()"
          /> -->
        </template>
      </Card>
    </popcorn>
    <Album
      tid="1"
      :album="圖片列表"
      :show="ALBUM.index"
      @closed="ALBUM.index = ''"
      @change="
        (val) => {
          ALBUM.index = val;
        }
      "
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  Card,
  Album,
  Input,
  Button,
  ImgUpload,
  Table,
} from "@/components/Shared";
import DatePicker from "@/components/FormItems/DatePicker.vue";
import { moneyout } from "@/usecase/moneyout";
export default {
  name: "MoneyOut",
  components: { DatePicker, Album, Card, Input, Button, ImgUpload, Table },
  props: {
    fMid: {
      type: Number,
      default: 0,
    },
    searchWords: {
      type: String,
      default: '',
    },
    搜尋帳號按鈕: {
      type: Boolean,
      default:false,
    },
  },
  data() {
    return {
      篩選種類:false,
   
      查看修改紀錄: [
        {
          name: "info",
          label: "備忘內容",
          align: "left",
        },
        {
          name: "date",
          label: "修改時間",
          align: "center",
        },
        {
          name: "name",
          label: "修改人",
          align: "center",
        },
      ],
      圖片列表: {},
      ALBUM: {
        index: "",
      },
      select: {},
      manager: {
        name: "",
        account: "",
        password: "",
        wallet: "",
        info: "",
      },
      statusText: {
        "@91": "審核中",
        "@0": "申請中",
        "@1": "完成交易",
        "@-1": "審核失敗",
        "@-2": "超過匯款時間",
      },
      statusColor: {
        "@91": "sts-gray",
        "@0": "sts-gray",
        "@1": "sts-green",
        "@-1": "sts-red",
        "@-2": "sts-red",
      },
      退款TXID: { 審核失敗原因: "", id: "" },
      匯款單: null,
      發票: null,
      目前選擇種類:'全部',
       重新渲染:0
    };
  },
  watch: {
    搜尋帳號按鈕(val){
     if(this.searchWords==''){
      return
     }
      if(val===true){
        this.目前選擇種類 = '全部'
        if (this.fMid != 0) {
          moneyout.時間起迄查詢會員出金紀錄({ 時間起 : '20230101', 時間迄 : '20301231',SearchVal: this.fMid });
      } else {
        moneyout.時間起迄查詢出金紀錄({ 時間起 : localStorage.getItem('入金時間起'), 時間迄 : localStorage.getItem('入金時間迄'),SearchVal:'null',FuzzyVal:this.searchWords});
      }
      }else{
        this.目前選擇種類 = '全部'
        if (this.fMid != 0) {
          moneyout.時間起迄查詢會員出金紀錄({ 時間起 : '20230101', 時間迄 : '20301231',SearchVal: this.fMid });
      } else {
        moneyout.時間起迄查詢出金紀錄({ 時間起 : localStorage.getItem('入金時間起'), 時間迄 : localStorage.getItem('入金時間迄'),SearchVal:'null',FuzzyVal:'null'   });
      }
      }
      console.log(val);
    }
  },
  mounted() {
    this.重新渲染++
    this.時間起迄查詢會員出金紀錄();
  },
  computed: {
    取得修改紀錄: function () {
      let obj = [];
      Object.keys(this.select.f異動紀錄).map((key) => {
        console.log(parseInt(key.replace("_", "")));
        const date = dayjs.unix(parseInt(key.replace("_", "")));
        obj.push({
          info:  this.select.f異動紀錄[key].info,
          date: date.format("YYYY.MM.DD"),
          name: this.select.f異動紀錄[key].name,
        });
        if(this.select.f審核描述!==""){
          obj[obj.length]={
            info: "駁回原因:" ,
            date: this.select.f審核描述,
            name: '',
          }
        }
      });
      return obj;
    },
    取得圖片列表: function (item) {
      return { 匯款單: item.f匯款單 };
    },
  },
  methods: {
    篩選種類點選(v){
      console.log('this.fMidthis.fMidthis.fMid',this.fMid);
    // this.reload()
    let n = '0'
    if(v=='審核中'){
      n ='91'
    }else  if(v=='申請中'){
      n ='0'
    }else  if(v=='完成交易'){
      n ='1'
    }else  if(v=='審核失敗'){
      n ='-1'
    }else  if(v=='全部'){
      n ='null'
    }
   
    this.重新渲染++
    this.目前選擇種類 = v
    if (this.fMid != 0) {
      moneyout.時間起迄查詢會員出金紀錄({ 時間起 : localStorage.getItem('入金時間起'), 時間迄 :  localStorage.getItem('入金時間迄'),SearchVal: this.fMid });
      } else {
        moneyout.時間起迄查詢出金紀錄({ 時間起 : localStorage.getItem('入金時間起'), 時間迄 : localStorage.getItem('入金時間迄'),SearchVal:n ,FuzzyVal:'null'});
      }
    },
    selectTime(val){
      if(typeof val.end =='object'){
      const 開始 = val.start
      const 開始年 = 開始.getFullYear()
      const 開始月 = 開始.getMonth()+1
      const 開始日 = 開始.getDate()
      const 開始時間 =`${開始年}${開始月<10?('0'+開始月):開始月}${開始日<10?('0'+開始日):開始日}`
      const 結束= val.end
      const 結束年 = 結束.getFullYear()
      const 結束月 = 結束.getMonth()+1
      const 結束日 = 結束.getDate()
      const 結束時間 =`${結束年}${結束月<10?('0'+結束月):結束月}${結束日<10?('0'+結束日):結束日}`
 
      localStorage.setItem('入金時間起', 開始時間)
      localStorage.setItem('入金時間迄', 結束時間)
      if (this.fMid != 0) {
        moneyout.時間起迄查詢會員出金紀錄({ 時間起 : 開始時間, 時間迄 : 結束時間,SearchVal: this.fMid });
      } else {
        moneyout.時間起迄查詢出金紀錄({ 時間起 : 開始時間, 時間迄 : 結束時間,SearchVal:'null',FuzzyVal:'null' });
      }
      }
      
    },
    時間起迄查詢會員出金紀錄() {
   
      const 三天前 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
      const 三天前年 = 三天前.getFullYear()
      const 三天前月 = 三天前.getMonth()+1
      const 三天前日 = 三天前.getDate()
      const 三天前時間 =`${三天前年}${三天前月<10?('0'+三天前月):三天前月}${三天前日<10?('0'+三天前日):三天前日}`

      const 當前年 = new Date().getFullYear()
      const 當前月 = new Date().getMonth()+1
      const 當前日 = new Date().getDate()
      const 當前時間 =`${當前年}${當前月<10?('0'+當前月):當前月}${當前日<10?('0'+當前日):當前日}`
      localStorage.setItem('入金時間起', 三天前時間)
      localStorage.setItem('入金時間迄', 當前時間)
      console.log(三天前時間);
      console.log(當前時間);
      if (this.fMid != 0) {
        moneyout.時間起迄查詢會員出金紀錄({ 時間起 : 三天前時間, 時間迄 : 三天前時間,SearchVal: this.fMid });
      } else {
        moneyout.時間起迄查詢出金紀錄({ 時間起 : 三天前時間, 時間迄 : 當前時間,SearchVal:'null',FuzzyVal:'null'   });
      }
    },
    successEvent() {},
    pop: function (cron, item) {
      this.$modal.show(cron);
      this.select = item;
    },
    顯示匯款單: function (item) {
      this.圖片列表 = { 匯款單: item.f匯款單 };
      this.ALBUM.index = "匯款單";
    },
    顯示退款單: function (src) {
      this.圖片列表 = src;
      this.ALBUM.index = "退款單";
    },
    顯示TXID: function (item) {
      this.select = item;
      this.$modal.show("查看TXID");
    },
    顯示退款TXID: function (item) {
      this.select = item;
      this.$modal.show("查看退款TXID");
    },
    顯示發票: function (src) {
      this.圖片列表 = src;
      this.ALBUM.index = "發票";
    },
    查看匯款圖: function (item) {
      const enable = item.f狀態 != 0;
      const look = Object.prototype.hasOwnProperty.call(
        item.f匯款紀錄,
        "匯款圖"
      );
      return {
        enable,
        look,
        total: enable || look,
      };
    },
    上傳匯款單: function (item) {
      const enable = item.f狀態 == 91;
      const look = item.f匯款單 != "";
      return {
        enable,
        look,
        total: enable || look,
      };
    },
    上傳TXID: function (item) {
    
      const enable = item.f狀態 == 91;
      const look = item.fTXID != "";
      return {
        enable,
        look,
        total: enable || look,
      };
    },
    上傳退款TXID: function (item) {
      const enable = item.f狀態 == 91;
      const look = item.f退款TXID != "";
      return {
        enable,
        look,
        total: enable || look,
      };
    },
    上傳TXID_EVENT() {
      // console.log(this.select, this.退款單);
      moneyout.上傳TXID(this.select.fId, this.TXID).then((res) => {
        this.$notify.success(res.message);
        if (this.fMid != 0) {
        moneyout.時間起迄查詢會員出金紀錄({ 時間起 : '20230101', 時間迄 : '20301231',SearchVal: this.fMid });
      } else {
        moneyout.時間起迄查詢出金紀錄({ 時間起 : localStorage.getItem('入金時間起'), 時間迄 : localStorage.getItem('入金時間迄'),SearchVal:'null',FuzzyVal:'null' });
      }
        this.$modal.hide("上傳TXID");
      });
    },
    上傳匯款單_EVENT() {
      // console.log(this.select, this.退款單);
      if(this.匯款單==null){
        this.$notify.warning("請上傳匯款單");
        return        
      }
      moneyout.上傳匯款單(this.select.f出金編號, this.匯款單).then((res) => {
        this.$notify.success(res.message);
        if (this.fMid != 0) {
        moneyout.時間起迄查詢會員出金紀錄({ 時間起 : '20230101', 時間迄 : '20301231',SearchVal: this.fMid });
      } else {
        moneyout.時間起迄查詢出金紀錄({ 時間起 : localStorage.getItem('入金時間起'), 時間迄 : localStorage.getItem('入金時間迄'),SearchVal:'null',FuzzyVal:'null' });
      }
        this.$modal.hide("上傳匯款單");
      });
    },
    上傳退款TXID_EVENT() {
      if(this.退款TXID.id==''){
        this.$notify.warning("請填寫此筆訂單TXID");
        return        
      }
      if(this.退款TXID.審核失敗原因==''){
        this.$notify.warning("請填寫此筆訂單審核失敗原因");
        return        
      }
      moneyout
        .上傳退款TXID(
          this.select.f出金編號,
          this.退款TXID.id,
          this.退款TXID.審核失敗原因
        )
        .then((res) => {
          this.$notify.success(res.message);
          if (this.fMid != 0) {
        moneyout.時間起迄查詢會員出金紀錄({ 時間起 : '20230101', 時間迄 : '20301231',SearchVal: this.fMid });
      } else {
        moneyout.時間起迄查詢出金紀錄({ 時間起 : localStorage.getItem('入金時間起'), 時間迄 : localStorage.getItem('入金時間迄'),SearchVal:'null',FuzzyVal:'null' });
      }
          this.$modal.hide("上傳退款TXID");
        });
    },
  },
};
</script>
<style scoped>
.color-gray {
  background-color: #686868;
}
.color-red {
  background-color: #d95b5b;
}
.color-gray {
  background-color: #389568;
}

.grayscale {
  -webkit-filter: grayscale(0.5) opacity(0.2);
}

.sts-gray {
  background-color: #686868;
}
.sts-green {
  background-color: #389568;
}

.sts-red {
  background-color: #d95b5b;
}
@media screen and (max-width:599px){
 .text-xl{
  font-size: 16px !important;
 }
}
</style>

<!-- switch (this.role) {
  case "manager":
    

    break;
  case "membership3":
    this.$store.commit("membership/addMembership3Data", {
      id: Date.now(),
      membership: 3,
      type: "company",
      status: "normal",
      apply_name: "新增用戶",
      account: this.account,
      onChain: false,
      // onLock: false,
      tax_number: "45678974",
      agent: "張桃桃",
      agentID: "N123456789",
      bank: "兆豐銀行",
      bank_branch: "高雄分行",
      bank_account: "00814531392927",
      company_address: "屏東縣萬丹鄉萬榮街９６號",
      wallet_address: "0xca7Ed2CdaF5b13542cd0A8782a317C2e02E7DD7D",
    });
    break;
} -->
